import createClient from 'openapi-fetch'
import type { paths as apiTypes } from '~/types/api/places-api-types'

export default () => {
  const $config = useRuntimeConfig().public

  const client = createClient<apiTypes>({
    baseUrl: $config.app.places.apiEndpoint
  })

  return client
}
